import request from '@/utils/requestV2'
const qs = require('qs')

// 获取发布类型
export function getAdTypes (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getadtypes',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 画面填充发布数据
export function getFillPublishPage (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getfillpublishpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取订单客户发布数据
export function getOrderPublishAdPage (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getorderpublishAdpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取发布源条件
export function getPublishSource (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getpublishsource',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 统计资源站点分布情况
export function getStationStatistic (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getstationstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取客户品牌站点资源分布详情
export function getStationStatisticDetail (data) {
  return request({
    url: '/ooh-msp/v3/publishstatistical/getstationstatisticdetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 资产发布统计
export function getAssetPublishStatistic (data) {
  return request({
    url: '/ooh-msp/v2/publishstatistical/getassetpublishstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取列车客户发布数据
export function getTrainPublishAdPage (data) {
  return request.post('/ooh-msp/v3/publishstatistical/getTrainPublishAdPage', qs.stringify(data))
}

// 获取客户品牌站点资源分布详情-列车
export function getTrainPublishDetail (data) {
  return request.post('/ooh-msp/v3/publishstatistical/getTrainPublishDetail', qs.stringify(data))
}
