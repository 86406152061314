<style scoped>
.demand-all{
  height: 700px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<template>
    <div>
        <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>

        <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>

        <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="4"
          :searchSchedules="[{startDate: publishDate, endDate: publishDate}]"
          @svg-finished="svgFinished" @svg-show-alldemand="svgShowAllDemand" @on-select="handleSelectSvgResource"
          @handleChange3dMode="handleBeginRevice3d" />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />

        <!-- 弹窗显示上刊照片反馈 -->
        <Modal v-model="showDemandImage" width="1000" :footer-hide="true">
            <p slot="header" class="text-center">
                <Icon type="ios-information-circle"></Icon>
                <span>灯箱编号：{{chooseResouceCode}}
                <Icon type="ios-analytics" class="p-l-5" />
                (
                  <span v-if="demandBrand !== ''">发布品牌：<span class="text-orange">{{demandBrand}}</span> </span>
                )
                </span>
            </p>

            <Row>
              <i-col v-if="publishContentList.length>1" span="4" class="demand-menu">
                <div v-for="(item,index) in publishContentList" :key="'s'+index" @click="handleClickScreen(item)">
                    <p :class="item===chooseScreen?'demand-table-row-active':'demand-table-row'" >
                      {{item.brandName}}
                      <span> ({{item.unitDuration}} 秒 | {{item.playTimes}} 次/天)</span>
                      <br/>
                      <span>{{item.startDate}} 至 {{item.endDate}}</span>
                    </p>
                </div>
              </i-col>
              <i-col :span="publishContentList.length>1?20:24"  class="text-center">
                <Row class="p-b-5">
                  <i-col span="24">
                      <div @click="handleChangeType(1)" :class="choosePhoneType === 1?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">任务素材</div>

                      <div v-if="workPhotoList.length>0" @click="handleChangeType(2)" :class="choosePhoneType === 2?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">反馈照片</div>
                      <span v-else class="remark m-l-5">任务未完成，无反馈照片</span>
                      <div @click="handleChangeType(3)" :class="choosePhoneType === 3?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">监播反馈</div>
                  </i-col>
                </Row>
                <img-light-box-component v-show="choosePhoneType === 1" ref="demandImgLightBox" :imgIndex.sync="demandImgIndex"/>
                <img-light-box-component v-show="choosePhoneType === 2" ref="workImgLightBox" :imgIndex.sync="workImgIndex"/>
                <img-light-box-component v-show="choosePhoneType === 3" ref="inspectImgLightBox" :imgIndex.sync="inspectIndex"/>
              </i-col>
            </Row>
        </Modal>

        <!-- 列表显示全部上刊信息 -->
        <Modal v-model="showAllDemandList" width="1000" :footer-hide="true">
            <div class="demand-all">
              <div v-for="(tab,index) in stationTabLables" :key="index">
                <h3>{{tab.label}}：</h3>
                <Row class="p-b-20" :gutter="16">
                  <i-col span="6" class="p-t-5" v-for="item in publishResourceList.filter(x => x.stationId === tab.stationId && x.floor === tab.floor)" :key="item.deviceId" >
                    <a @click="handleSelectSvgResource(item.deviceId)">{{item.resourceCode}} ({{item.brandName===''?'-':item.brandName}})</a>
                  </i-col>
                </Row>
              </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import ImgLightBoxComponent from '@/components/common/ImgLightBox'

import { getStationStatistic, getStationStatisticDetail } from '@/api/msp/publishstatistical'
import { getElecStationStats, getElecStationPublishDetail } from '@/api/msp/elecpublishstats'
// import { getPublisherAssetList } from '@/api/rim/asset'
import { getPackageAssetList } from '@/api/rim/asset'

export default {
  components: {
    SvgLine, GeoMap, SvgStation, ThreeStation, ImgLightBoxComponent
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0,
      publishResourceList: [], // 上刊发布的资源信息

      showDemandImage: false,
      // 上刊反馈参数
      chooseResouceCode: '',

      publishContentList: [], // 上刊画面集合
      chooseScreen: null, // 当前选中的画面对象实体
      demandBrand: '',
      choosePhoneType: 1,
      demandPhotoList: [], // 上刊素材照片列表
      demandImgIndex: 0,
      workPhotoList: [], // 工单照片
      workImgIndex: 0,
      inspectAttachList: [], // 监播反馈素材
      inspectIndex: 0,

      showAllDemandList: false,
      stationTabLables: []
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val

      // 仅限地铁线路图，需要隐藏其他线路
      if (val && this.showMapType === 1) {
        getPackageAssetList().then(res => {
          if (res && !res.errcode) {
            this.$refs.svgline.hideOtherAsset(res.map(x => x.id))
          }
        })
      }
    },
    LoadMapData (stations) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.showMapType === 1) {
          const formatStations = stations.map(x => {
            return {
              quantity: x.count,
              stationId: x.stationId,
              stationName: x.stationName
            }
          })
          this.$refs.svgline.loadResourceStations(formatStations)
        } else {
          this.$refs.geoMap.loadResourceStations([])
        }
      } else {
        setTimeout(() => {
          this.LoadMapData(stations)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    initDemandStations () {
      const postData = {
        publishDate: this.publishDate,
        adTypes: this.adType,
        advertiserId: this.advertiserId,
        brandId: this.brandId,
        fileKey: this.fileId,
        keyword: this.keyword
      }

      if (this.mediaType === 0) {
        getStationStatistic(postData).then(res => {
          this.LoadMapData(res)
        })
      } else { // 电子屏是单独的接口
        getElecStationStats(postData).then(res => {
          this.LoadMapData(res)
        })
      }
    },
    svgFinished () { // SVG站点地图加载完毕
      const postData = {
        publishDate: this.publishDate,
        adTypes: this.adType,
        advertiserId: this.advertiserId,
        brandId: this.brandId,
        fileKey: this.fileId,
        stationIds: this.chooseStationId,
        keyword: this.keyword
      }

      if (this.mediaType === 0) {
        getStationStatisticDetail(postData).then(res => {
          this.publishResourceList = res
          this.$refs.svgStation.tagResourceAndName(res.map(x => {
            return {
              deviceId: x.deviceId,
              brandName: x.brandName === '' ? x.advertiserName : x.brandName,
              stationId: x.stationId,
              floor: x.floor
            }
          }))
          this.$refs.svgStation.choseSpinShow()
        })
      } else {
        getElecStationPublishDetail(postData).then(res => {
          this.publishResourceList = res
          this.$refs.svgStation.tagResourceAndName(res.map(x => {
            return {
              deviceId: x.deviceId,
              brandName: '',
              stationId: x.stationId,
              floor: x.floor,
              elecPublishContentList: x.publishContentList
            }
          }))
          this.$refs.svgStation.choseSpinShow()
        })
      }
    },
    threeFinished () {

    },
    svgShowAllDemand () { // 列表显示全部的上刊信息
      this.stationTabLables = []
      let label
      this.publishResourceList.forEach(element => {
        label = (element.floor > 0) ? '地上' : '地下'
        label += element.floor + '层'

        if (!this.stationTabLables.find(x => x.stationId === element.stationId && x.floor === element.floor)) {
          this.stationTabLables.push({
            stationId: element.stationId,
            floor: element.floor,
            label: label
          })
        }
      })

      this.showAllDemandList = true
    },
    handleSelectSvgResource (deviceId) {
      // // 从上刊资源列表找到该资源
      const demandResourceBean = this.publishResourceList.find(x => x.deviceId === deviceId)
      if (demandResourceBean) {
        this.showDemandImage = true
        this.chooseResouceCode = demandResourceBean.resourceCode

        // 兼容电子屏多个上刊画面
        let allDemandList = []
        if (demandResourceBean.publishContentList && demandResourceBean.publishContentList.length > 0) {
          allDemandList = demandResourceBean.publishContentList
        } else {
          allDemandList = [demandResourceBean]
        }

        this.publishContentList = allDemandList
        this.handleClickScreen(allDemandList[0])
      }
    },
    handleChangeType (type) {
      this.choosePhoneType = type

      const postData = {
        startIndex: 0,
        imgList: []
      }

      if (type === 1) { // 任务素材
        postData.startIndex = this.demandImgIndex
        postData.imgList = this.demandPhotoList.map(x => x.fileKey)
        this.$refs.demandImgLightBox.init(postData)
      } else if (type === 2) { // 反馈照片
        postData.startIndex = this.workImgIndex
        postData.imgList = this.workPhotoList.map(x => x.fileKey)
        this.$refs.workImgLightBox.init(postData)
      } else { // 监播反馈
        postData.startIndex = 0
        postData.imgList = this.inspectAttachList.map(x => x.fileKey)
        this.$refs.inspectImgLightBox.init(postData)
      }
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    },
    handleClickScreen (item) {
      this.chooseScreen = item

      this.demandImgIndex = 0
      this.workImgIndex = 0
      this.inspectIndex = 0

      this.demandBrand = item.brandName
      this.demandPhotoList = item.taskitemFileList
      this.workPhotoList = item.feedBackAttachList
      this.inspectAttachList = item.inspectAttachList

      this.handleChangeType(1)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.statistic.beginUpdate
    },
    publishDate () {
      return this.$store.state.statistic.publishDate
    },
    adType () {
      return this.$store.state.statistic.adType
    },
    advertiserId () {
      return this.$store.state.statistic.advertiserId
    },
    brandId () {
      return this.$store.state.statistic.brandId
    },
    fileId () {
      return this.$store.state.statistic.fileId
    },
    keyword () {
      return this.$store.state.statistic.keyword
    },
    mediaType () {
      return this.$store.state.statistic.mediaType
    }
  },
  watch: {
    beginUpdate () {
      this.initDemandStations()
    }
  }

}
</script>
