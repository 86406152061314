import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 电子屏站点发布统计
 * @param {Object} data
 */
export function getElecStationStats (data) {
  return request(
    {
      url: '/ooh-msp/v3/elecpublishstats/getElecStationStats',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 分页查询电子屏在刊客户信息
 * @param {Object} data
 */
export function getElecPublishAdPage (data) {
  return request(
    {
      url: '/ooh-msp/v3/elecpublishstats/getElecPublishAdPage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 获取客户品牌站点资源分布详情
 * @param {Object} data
 */
export function getElecStationPublishDetail (data) {
  return request(
    {
      url: '/ooh-msp/v3/elecpublishstats/getElecStationPublishDetail',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
